import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import ScrollToTop from "./ScrollToTop";
import { IoIosArrowRoundBack } from "react-icons/io";
import axios from "axios";
import { baseUrl, imgBaseUrl } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Papa from "papaparse";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import UploadProgressBar from "./UploadProgressBar";

const BulkUploadData = ({ onLogout }) => {
  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileDetails, setFileDetails] = useState([]);
  const [duplicates, setDuplicates] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const token = localStorage.getItem("token");

  const allInfluencers = useSelector(
    (store) => store.influencers.influencersList
  );
  const userData = useSelector((store) => store.user.userData);
  const canDeleteFile = useSelector(
    (store) => store.user.userData.permissions.deleteFile
  );
  const isAdmin = useSelector((store) => store.user.userData.isAdmin);

  const columns = [
    { field: "fileName", headerName: "File Name", width: 260 },
    {
      field: "fileLink",
      headerName: "File Link",
      width: 130,
      renderCell: (params) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          className="file_link"
        >
          File Link
        </a>
      ),
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      width: isAdmin || canDeleteFile ? 130 : 180,
    },
    {
      field: "date",
      headerName: "Date (MM/DD/YY)",
      width: isAdmin || canDeleteFile ? 170 : 180,
    },
    { field: "time", headerName: "Time", width: 130 },
    ...(isAdmin || canDeleteFile
      ? [
          {
            field: "delete",
            headerName: "Delete",
            width: 60,
            renderCell: (params) => (
              <button
                className="btn btn_delete"
                onClick={() => handleDelete([params.row.id])}
              >
                <MdDelete />
              </button>
            ),
          },
        ]
      : []),
  ];

  const notifySuccess = (msg) =>
    toast.success(msg || "Data added successfully!!");
  const notifyError = (err) => {
    toast.error(err);
  };

  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(event.target.files[0]);
      setIsUploaded(true);

      try {
        const csvData = await parseCSV(selectedFile);
        const { duplicateDataCount } = removeDuplicates(csvData);
        setTotalEntries(csvData.length);
        setDuplicates(duplicateDataCount);
      } catch (error) {
        console.error("Error processing file:", error);
        notifyError("Error processing file");
      }
    }
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("uploadBy", userData?.name);
    formData.append("csvFile", file);
    setIsUploaded(true);

    try {
      const csvData = await parseCSV(file);
      const { uniqueData } = removeDuplicates(csvData);
      await sendToAPI(uniqueData, 500);
      await axios.post(`${baseUrl}/upload-file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setTotalEntries(0);
      setDuplicates(0);
      notifySuccess();
      fetchFileDetails();
    } catch (error) {
      console.error("Error uploading file:", error);
      notifyError(error.message);
    } finally {
      setIsUploaded(false);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("hidden-file-input").click();
  };

  const fetchFileDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/files`, {
        headers: {
          "auth-token": token,
        },
      });
      setFileDetails(response.data);
    } catch (error) {
      console.error("Error fetching file details:", error);
    }
  };

  useEffect(() => {
    fetchFileDetails();
  }, []);

  const rows = fileDetails?.map((file) => ({
    id: file?._id,
    fileName: file?.fileName,
    fileLink: `${imgBaseUrl}/${file.fileLink}`,
    uploadedBy: file?.uploadBy,
    date: file.date,
    time: file.time,
  }));

  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const dataWithDefaults = results.data.map((row) => {
            return {
              Name: row.Name || "NA",
              Username: row.Username || "NA",
              instagramProfile: row.instagramProfile || "NA",
              Followers: Number(row?.Followers?.replace(/,/g, "")) || 0,
              Type: row.Type || "NA",
              Email: row.Email || "NA",
              contactNum: row.contactNum || "NA",
              whatsappNum: row.whatsappNum || "NA",
              Quallity: row.Quallity || "NA",
              primaryCategory: row.primaryCategory || "NA",
              Gender: row.Gender || "NA",
              avgViews: row.avgViews || "NA",
              Address: row.Address || "NA",
              pincode: row.pincode || "NA",
              Location: row.Location || "NA",
              Commercials:
                row.Commercials === "" ||
                typeof row.Commercials === "string" ||
                !row.Commercials
                  ? { post: "NA", reel: "NA", story: "NA" }
                  : row.Commercials,
              Youtube: row.Youtube || "NA",
              YoutubeName: row.YoutubeName === "NA" ? null : row.YoutubeName,
              profilePic: row.profilePic,
              Source: userData?.name,
              uploadType: "Bulk",
            };
          });
          resolve(dataWithDefaults);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const sendToAPI = async (data, batchSize) => {
    const totalEntries = data.length;
    let processedEntries = 0;

    for (let i = 0; i < data.length; i += batchSize) {
      const batch = data.slice(i, i + batchSize);
      try {
        setIsUploading(true);
        const batchLength = batch.length;
        await axios.post(`${baseUrl}/upload`, batch, {
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const percentCompleted = Math.floor(
              (current / total) * (batchLength / totalEntries) * 100
            );
            setUploadProgress(
              (prev) => prev + percentCompleted * (batchLength / totalEntries)
            );
          },
        });

        processedEntries += batchLength;
        const overallProgress = Math.floor(
          (processedEntries / totalEntries) * 100
        );
        setUploadProgress(overallProgress);

        console.log(`Batch ${Math.ceil(i / batchSize) + 1} sent successfully`);
      } catch (error) {
        console.error(`Error in batch ${Math.ceil(i / batchSize) + 1}:`, error);
        // notifyError("Error sending data to API");
      }
    }

    setIsUploading(false);
  };

  const removeDuplicates = (csvData) => {
    const uniqueData = [];
    const duplicateData = [];
    const influencerUsernames = new Set();

    allInfluencers?.influencers?.forEach((influencer) => {
      influencerUsernames.add(influencer?.Username);
    });

    csvData.forEach((item) => {
      if (!influencerUsernames.has(item.Username)) {
        uniqueData.push(item);
      } else {
        duplicateData.push(item);
      }
    });

    return {
      uniqueData,
      duplicateDataCount: duplicateData.length,
      duplicateData,
    };
  };

  // const sendToAPI = async (data) => {
  //   try {
  //     await axios.post(`${baseUrl}/upload`, data, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error sending data to API:", error);
  //     notifyError("Error sending data to API");
  //   }
  // };

  const handleSelectionChange = (newSelection) => {
    setSelectedFiles(newSelection);
  };

  const handleDeleteSelected = () => {
    handleDelete(selectedFiles);
  };

  const deleteFilesInBatches = async (idsToDelete, batchSize) => {
    for (let i = 0; i < idsToDelete.length; i += batchSize) {
      const batch = idsToDelete.slice(i, i + batchSize);
      try {
        await axios.delete(`${baseUrl}/delete-files`, {
          data: { ids: batch, deletedBy: userData?.name },
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(
          `Batch ${Math.ceil((i + 1) / batchSize)} deleted successfully`
        );
      } catch (error) {
        console.error(
          `Error deleting batch ${Math.ceil((i + 1) / batchSize)}:`,
          error
        );
        throw error;
      }
    }
  };

  const handleDelete = async (idsToDelete) => {
    if (idsToDelete.length === 0) {
      notifyError("No files selected for deletion");
      return;
    }

    const batchSize = 500;

    try {
      console.log("Deleting files with IDs:", idsToDelete);
      await deleteFilesInBatches(idsToDelete, batchSize);

      notifySuccess("Files moved to trash successfully");
      setFileDetails((prevFileDetails) =>
        prevFileDetails.filter((file) => !idsToDelete.includes(file._id))
      );
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      notifyError("Error deleting files");
    }
  };

  // const handleDelete = async (idsToDelete) => {
  //   try {
  //     console.log("Deleting files with IDs:", idsToDelete);
  //     await axios.delete(`${baseUrl}/delete-files`, {
  //       data: { ids: idsToDelete, deletedBy: userData?.name },
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     notifySuccess("Files moved to trash successfully");
  //     setFileDetails((prevFileDetails) =>
  //       prevFileDetails.filter((file) => !idsToDelete.includes(file._id))
  //     );
  //     setSelectedFiles([]);
  //   } catch (error) {
  //     console.error("Error deleting files:", error);
  //     notifyError("Error deleting files");
  //   }
  // };

  return (
    <div className="wrapper">
      <Sidebar onLogout={onLogout} />
      <ScrollToTop />
      <ToastContainer theme="colored" />
      <div
        className="main-panel"
        style={{ backgroundColor: "rgb(244, 248, 251)" }}
      >
        <div className="dashboard_main">
          <div className="dashboard_header">
            <Link to={"/add-data"} className="go_back">
              <IoIosArrowRoundBack className="icon" />
            </Link>
            <h4>Bulk Upload Influencer</h4>
          </div>
          <div className="add_data_main mt-3">
            <div className="form_box">
              <div className="col-md-12 mt-5">
                <div className="upload_dp_box">
                  <div className="dashed">
                    <h3>Select csv file to upload</h3>
                    <input
                      id="hidden-file-input"
                      type="file"
                      accept=".csv"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    {file && isUploaded && (
                      <div className="btn_row">
                        <p className="mb-2">{file?.name}</p>
                      </div>
                    )}
                    {file && isUploaded && (
                      <>
                        <p>
                          <span className="text-bold">Total entries: </span>
                          {totalEntries}
                        </p>
                        <p>
                          <span className="text-bold">Duplicate entries: </span>
                          {duplicates}
                        </p>
                        {totalEntries === duplicates && totalEntries !== 0 && (
                          <p className="text-danger">
                            There is no new data to upload please select another
                            file
                          </p>
                        )}
                      </>
                    )}
                    {isUploading && (
                      <>
                        <UploadProgressBar progress={uploadProgress} />
                        <p>{Math.round(uploadProgress)}%</p>
                      </>
                    )}
                    <button
                      className="mt-3"
                      onClick={
                        file && isUploaded && totalEntries !== duplicates
                          ? handleUpload
                          : triggerFileInput
                      }
                    >
                      {file && isUploaded && totalEntries !== duplicates
                        ? "Upload"
                        : "Select File"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                {fileDetails.length > 0 && (
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      checkboxSelection
                      onRowSelectionModelChange={handleSelectionChange}
                    />
                    {(isAdmin || canDeleteFile) && selectedFiles.length > 0 && (
                      <div className="selected_row_delete">
                        <button
                          className="btn btn_delete2"
                          onClick={handleDeleteSelected}
                        >
                          <MdDelete />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadData;
