import React, { useState } from "react";
import Sidebar from "../Sidebar";
import "./Influencers.scss";
import useGetAllInfluencers from "../../utils/useGetAllInfluencers";
import ScrollToTop from "../ScrollToTop";
import TopFilters from "./TopFilters";
import LeftFilters from "./LeftFilters";
import InfluencersBody from "./InfluencersBody";
import { FaList } from "react-icons/fa6";
import { BsGrid3X3GapFill } from "react-icons/bs";
import TableView from "./TableView";
import ProfilePic from "../ProfilePic";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { useSelector } from "react-redux";

const Influencers = ({ onLogout }) => {
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [location, setLocation] = useState("");
  const [followers, setFollowers] = useState("");
  const [quality, setQuality] = useState("");
  const [gender, setGender] = useState("");
  const [avgViews, setAvgViews] = useState("");
  const [primaryCat, setPrimaryCat] = useState([]);
  const [influencerType, setInfluencerType] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isGridView, setIsGridView] = useState(true);
  const [typeOfUpload, setTypeOfUpload] = useState("All");
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [filterModel, setFilterModel] = useState({
    items: [{ field: "", operator: "", value: "" }],
  });

  const user = useSelector((store) => store?.user?.userData);

  const handleInfluencerTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setInfluencerType((prev) => [...prev, value]);
    } else {
      setInfluencerType((prev) => prev.filter((type) => type !== value));
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPrimaryCat((prev) => [...prev, value]);
    } else {
      setPrimaryCat((prev) => prev.filter((type) => type !== value));
    }
  };

  const [isLoading, allInfluencers, refreshData, totalCount] =
    useGetAllInfluencers(
      name,
      userName,
      quality,
      primaryCat,
      gender,
      influencerType,
      location,
      followers,
      avgViews,
      fromDate,
      toDate,
      isGridView,
      typeOfUpload,
      paginationModel,
      setPaginationModel,
      filterModel
    );

  const handleViewChange = (view) => {
    setIsGridView(view === "grid");
    if (view === "table") {
      refreshData();
    }
  };

  const token = localStorage.getItem("token");

  const handleExportAll = async () => {
    setIsLoadingDownload(true);
    const allData = [];
    const pageSize = 500;
    let totalEntries = 0;

    try {
      const firstData = await fetch(
        `${baseUrl}/all-influencers?page=${1}&limit=${pageSize}&name=${name}&Username=${userName}&Quallity=${quality}&primaryCategory=${primaryCat}&Gender=${gender}&Type=${influencerType}&Location=${location}&Followers=${followers}&avgViews=${avgViews}&fromDate=${fromDate}&toDate=${toDate}&uploadType=${typeOfUpload}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      const firstResponse = await firstData.json();
      totalEntries = firstResponse.total;
      const totalPages = Math.ceil(totalEntries / pageSize);

      allData.push(...firstResponse.influencers);

      for (let page = 2; page <= totalPages; page++) {
        const secondData = await fetch(
          `${baseUrl}/all-influencers?page=${page}&limit=${pageSize}&name=${name}&Username=${userName}&Quallity=${quality}&primaryCategory=${primaryCat}&Gender=${gender}&Type=${influencerType}&Location=${location}&Followers=${followers}&avgViews=${avgViews}&fromDate=${fromDate}&toDate=${toDate}&uploadType=${typeOfUpload}`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
        const response = await secondData.json();
        if (response && response.influencers) {
          allData.push(...response.influencers);
        } else {
          console.error(`No data found on page ${page}`);
        }
      }

      const response = await axios.post(
        `${baseUrl}/download-influencers`,
        allData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "influencers.csv");
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the CSV:", error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Influencers</h4>
                <div className="d-flex align-items-center">
                  {(user?.permissions?.exportData || user?.isAdmin) && (
                    <button
                      onClick={handleExportAll}
                      disabled={isLoadingDownload}
                      className="me-3 export_btn"
                    >
                      {isLoadingDownload ? "Exporting..." : "Export"}
                    </button>
                  )}
                  <div className="table_grid">
                    <div
                      className={isGridView ? "active" : ""}
                      onClick={() => handleViewChange("grid")}
                    >
                      <BsGrid3X3GapFill />
                    </div>
                    <div
                      className={!isGridView ? "active" : ""}
                      onClick={() => handleViewChange("table")}
                    >
                      <FaList />
                    </div>
                  </div>
                  <div className="logout_box ms-3">
                    <ProfilePic onLogout={onLogout} />
                  </div>
                </div>
              </div>
              <TopFilters
                name={name}
                setName={setName}
                userName={userName}
                setUserName={setUserName}
                location={location}
                setLocation={setLocation}
              />
            </div>
            <div className="row" style={{ marginTop: "123px" }}>
              <div className="col-md-4">
                <LeftFilters
                  followers={followers}
                  setFollowers={setFollowers}
                  handleInfluencerTypeChange={handleInfluencerTypeChange}
                  handleCategoryChange={handleCategoryChange}
                  gender={gender}
                  setGender={setGender}
                  avgViews={avgViews}
                  setAvgViews={setAvgViews}
                  quality={quality}
                  setQuality={setQuality}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                  typeOfUpload={typeOfUpload}
                  setTypeOfUpload={setTypeOfUpload}
                />
              </div>
              <div className="col-md-8 overflow-hidden">
                {isGridView ? (
                  <InfluencersBody
                    isLoading={isLoading}
                    allInfluencers={allInfluencers}
                  />
                ) : (
                  <TableView
                    isLoading={isLoading}
                    allInfluencers={allInfluencers}
                    refreshData={refreshData}
                    setPaginationModel={setPaginationModel}
                    paginationModel={paginationModel}
                    total={totalCount}
                    setFilterModel={setFilterModel}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Influencers;
