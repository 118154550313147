import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetTotalInfluencers = (isAuthenticated) => {
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const getData = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/influencers-without-pagination`,
        { headers: { "auth-token": token } }
      );
      const data = await response.json();
      if (data) {
        setAllInfluencers(data);
      } else {
        setAllInfluencers([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setAllInfluencers([]);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getData();
    }
  }, [isAuthenticated]);

  return [isLoading, allInfluencers];
};

export default useGetTotalInfluencers;
