import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { imgBaseUrl } from "../apiConfig";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";

const socket = io(imgBaseUrl);

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const notifyBoxRef = useRef(null);

  const showNotificationHandler = () => {
    setShowNotifications((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (notifyBoxRef.current && !notifyBoxRef.current.contains(event.target)) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    if (showNotifications) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotifications]);

  useEffect(() => {
    socket.on("newNotification", (notification) => {
      setNotifications((prev) => [notification, ...prev]);
    });
    return () => {
      socket.off("newNotification");
    };
  }, []);

  return (
    <>
      <div className="notification_box" onClick={showNotificationHandler}>
        <IoMdNotificationsOutline className="notification_icon" />
        {notifications.length > 0 && <div className="notify_dot"></div>}
      </div>
      {showNotifications && (
        <div className={"dropdown_main position-absolute"} ref={notifyBoxRef}>
          <ul>
            <p>Notifications</p>
            <hr />
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <li className="notification_text" key={index}>
                  <Link
                    to={`/campaign/campaign-details/${notification.campaignId}`}
                  >
                    {notification?.message}
                  </Link>
                </li>
              ))
            ) : (
              <p>No notifications</p>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default Notifications;
