export const checkValidate = (email, password) => {
  const isEmailValid = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(email);
  const isPasswordValid = /^[A-Za-z]\w{7,14}$/.test(password);

  if (!isEmailValid) return "Invalid Email Address";
  if (!isPasswordValid) return "Incorrect Password";

  return null;
};

export const addDataValidation = (
  name,
  instagramProfile,
  youtubeLink,
  email,
  mobile,
  whatsapp,
  pincode
) => {
  const isNameValid = /^[a-zA-Z\s'-]{2,35}$/.test(name);
  const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
    email
  );
  const mobileNumberPattern = /^\d{10}$/.test(mobile);
  const isValidWhatsapp = /^(\+\d{1,3})?\d{10,12}$/.test(whatsapp);
  const isValidInstagramProfile =
    /\b(?:https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?\b/.test(
      instagramProfile
    );

  const isValidYoutubeLink =
    /\b(?:https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?\b/.test(
      youtubeLink
    );

  const isValidPinCode = /\b[A-Z0-9]{1,10}\b/.test(pincode);

  if (!isNameValid) return "Name should be 2-30 characters long";
  if (!isValidInstagramProfile) return "Invalid Instagram Profile Link";
  if (!isValidYoutubeLink && youtubeLink !== "")
    return "Invalid Youtube Channel Link";
  if (!isEmailValid && email !== "") return "Invalid Email Address";
  if (!mobileNumberPattern && mobile !== "") return "Invalid mobile number";
  if (!isValidWhatsapp && whatsapp !== "") return "Invalid whatsapp number";
  if (!isValidPinCode && pincode !== "") return "Invalid Pincode";
  return null;
};
