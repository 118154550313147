import React, { useRef, useState } from "react";
import SettingSidebar from "./SettingSidebar";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { IoIosWarning } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import ForgotPassword from "./ForgotPassword";
import { useSelector } from "react-redux";
import ProfilePic from "../ProfilePic";

const Password = ({ onLogout }) => {
  const [status, setStatus] = useState("");
  const currentPassword = useRef(null);
  const newPass = useRef(null);
  const confirmNewPass = useRef(null);
  const userData = useSelector((store) => store.user.userData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("new pass: ", newPass, "& confirm: ", confirmNewPass);
    if (newPass.current.value !== confirmNewPass.current.value) {
      setStatus("Passwords do not match");
      return;
    }

    try {
      console.log("Sending update password request");
      const response = await axios.post(`${baseUrl}/update-password`, {
        email: userData?.email,
        currentPassword: currentPassword.current.value,
        newPassword: newPass.current.value,
      });
      console.log("Response from server:", response.data);
      currentPassword.current.value = null;
      newPass.current.value = null;
      confirmNewPass.current.value = null;
      setStatus("Password updated successfully!");
    } catch (error) {
      console.error(
        "Error updating password:",
        error.response?.data || error.message
      );
      setStatus("Failed to update password");
    }
  };

  const cancelPass = () => {
    currentPassword.current.value = null;
    newPass.current.value = null;
    confirmNewPass.current.value = null;
  };

  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Settings</h4>
                <div className="logout_box">
                  <ProfilePic onLogout={onLogout} />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <SettingSidebar />
              </div>
              <div className="col-md-9 mt-3">
                <div className="setting_body">
                  <div className="body">
                    <h5 className="mb-0">Password</h5>
                    <p className="light_text">
                      Manage settings for your account password
                    </p>
                    <div className="divider_line w-100"></div>
                    <h6 className="mt-3">Update Password</h6>
                    <form onSubmit={handleSubmit}>
                      <input
                        ref={currentPassword}
                        type="text"
                        placeholder="Current Password"
                        className="mt-2 pswrd_input"
                      />
                      <input
                        ref={newPass}
                        type="password"
                        placeholder="New Password"
                        className="mt-4 pswrd_input"
                      />
                      <input
                        ref={confirmNewPass}
                        type="password"
                        placeholder="Confirm New Password"
                        className="mt-4 pswrd_input"
                      />
                      <div className="mt-4">
                        <button
                          className="btn cancel_password"
                          type="button"
                          onClick={cancelPass}
                        >
                          Cancel
                        </button>
                        <button className="btn update_password" type="submit">
                          Update Password
                        </button>
                      </div>
                    </form>
                    <ForgotPassword />
                    {status && (
                      <p
                        className={
                          status.includes("successfully")
                            ? "mt-3 text-success text-bold"
                            : "mt-3 text-danger text-bold"
                        }
                      >
                        {status.includes("successfully") ? (
                          <>
                            <TiTick style={{ fontSize: "26px" }} />
                            {status}
                          </>
                        ) : (
                          <>
                            <IoIosWarning /> {status}
                          </>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
