import { Skeleton, Stack } from "@mui/material";
import React from "react";

const CircleGraphLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "150px" }} />
      <Skeleton variant="circular" width={250} height={250} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100px" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80px" }} />
    </Stack>
  );
};

export default CircleGraphLoader;
