import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CampPieChart from "./CampPieChart";
import ClientDetails from "./ClientDetails";
import CircleGraphLoader from "../dashboard/CircleGraphLoader";
import { useParams } from "react-router-dom";
import useGetCampaignById from "../../utils/useGetCampaignById";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CampGraphsTab() {
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const { id } = params;
  const [campaignData, , isLoading] = useGetCampaignById(id, 0, Infinity);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const uniqueCategories = [
    ...new Set(
      campaignData?.campaign?.influencers?.flatMap(
        (item) => item.primaryCategory
      )
    ),
  ];

  const uniqueType = [
    ...new Set(campaignData?.campaign?.influencers?.map((item) => item.Type)),
  ];
  const typeCategory = uniqueCategories?.map((category, index) => {
    const colorPalette = [
      "var(--primary-color)",
      "#80C4E9",
      "#87bc45",
      "#f46a9b",
      "#ef9b20",
      "#edbf33",
    ];
    return {
      label: category || "Others",
      color: colorPalette[index % colorPalette.length],
    };
  });
  const typeType = [
    { label: "Nano", color: "var(--primary-color)" },
    { label: "Micro", color: "#80C4E9" },
    { label: "Mid Tier", color: "#87bc45" },
    { label: "Macro", color: "#f46a9b" },
    { label: "Mega", color: "#ef9b20" },
    { label: "Celebrity", color: "#edbf33" },
    // { label: "Others", color: "#ede15b" },
  ];
  const data2 = typeCategory?.map((type) => {
    const count = campaignData?.campaign?.influencers?.filter((item) =>
      item.primaryCategory.includes(type.label)
    );
    return {
      label: type.label,
      value: Math.round(
        (count.length / campaignData?.campaign?.influencers?.length) * 100
      ),
      color: type.color,
    };
  });

  const data1 = typeType?.map((type) => {
    const count = campaignData?.campaign?.influencers?.filter((item) => {
      return item.Type.toLowerCase() === type.label.toLowerCase();
    });
    return {
      label: type.label,
      value: Math.round(
        (count?.length / campaignData?.campaign?.influencers?.length) * 100
      ),
      color: type.color,
    };
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Niche"
            {...a11yProps(0)}
            sx={{
              fontWeight: "600",
              "&.Mui-selected": {
                color: "#ff5722",
              },
            }}
          />
          <Tab
            label="Type"
            {...a11yProps(1)}
            sx={{
              fontWeight: "600",
              color: "gray",
              "&.Mui-selected": {
                color: "#ff5722",
              },
            }}
          />
          <Tab
            label="Client Details"
            {...a11yProps(2)}
            sx={{
              fontWeight: "600",
              color: "gray",
              "&.Mui-selected": {
                color: "#ff5722",
              },
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {isLoading ? (
          <CircleGraphLoader />
        ) : (
          <CampPieChart
            influencers={campaignData?.campaign?.influencers}
            total={campaignData?.campaign?.influencers?.length}
            uniqueCategories={uniqueCategories}
            data2={data2}
            types={typeCategory}
            isType={false}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isLoading ? (
          <CircleGraphLoader />
        ) : (
          <CampPieChart
            influencers={campaignData?.campaign?.influencers}
            total={campaignData?.campaign?.influencers?.length}
            uniqueCategories={uniqueType}
            data2={data1}
            types={typeType}
            isType={true}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ClientDetails
          clientName={campaignData?.campaign?.clientName}
          clientAddress={campaignData?.campaign?.clientAddress}
          gstDetails={campaignData?.campaign?.gstDetails}
          clientCode={campaignData?.campaign?.clientName[0]?.clientCode}
        />
      </CustomTabPanel>
    </Box>
  );
}

export default CampGraphsTab;
