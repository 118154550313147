import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { PieChart } from "@mui/x-charts/PieChart";

function CampPieChart({ influencers, data2, types, isType }) {
  // const data2 = types.map((type) => {
  //   const count = influencers.filter((item) =>
  //     item.primaryCategory.includes(type.label)
  //   );
  //   return {
  //     label: type.label,
  //     value: Math.round((count.length / total) * 100),
  //     color: type.color,
  //   };
  // });

  const series = [
    {
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 4,
      cornerRadius: 5,
      startAngle: -90,
      endAngle: 180,
      cx: 150,
      cy: 150,
      id: "series-1",
      data: data2,
    },
  ];

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: "100%" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <PieChart
          series={series}
          width={321}
          height={300}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        <div className="pie_overview">
          {!isType
            ? types.map((type) => {
                const count = influencers.filter((item) =>
                  item.primaryCategory.includes(type.label)
                ).length;

                return (
                  <div key={type.label}>
                    <div
                      className="orange_box"
                      style={{ backgroundColor: `${type.color}` }}
                    ></div>
                    <div>
                      <h6 className="mb-0">{count}</h6>
                      <p>{type.label}</p>
                    </div>
                  </div>
                );
              })
            : types.map((type) => {
                const count = influencers.filter((item) =>
                  item.Type.toLowerCase().includes(type.label.toLowerCase())
                ).length;

                return (
                  <div key={type.label}>
                    <div
                      className="orange_box"
                      style={{ backgroundColor: `${type.color}` }}
                    ></div>
                    <div>
                      <h6 className="mb-0">{count}</h6>
                      <p>{type.label}</p>
                    </div>
                  </div>
                );
              })}
        </div>
      </Box>
    </Stack>
  );
}

export default CampPieChart;
