import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetUserById = (id) => {
  const [userData, setUserData] = useState([]);
  const url = window.location.href;
  const token = localStorage.getItem("token");
  const getData = async () => {
    try {
      const response = await fetch(`${baseUrl}/user-data/${id}`, {
        headers: {
          "auth-token": token,
        },
      });
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.log(error);
      setUserData([]);
    }
  };

  useEffect(() => {
    if (!url.includes("create-user")) {
      getData();
    }
  }, [id, url]);
  return [userData];
};

export default useGetUserById;
