import LinearProgress from "@mui/material/LinearProgress";

const UploadProgressBar = ({ progress }) => {
  return (
    <div style={{ width: "100%", margin: "10px 0" }}>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default UploadProgressBar;
