import React from "react";
import CircleGraph from "./CircleGraph";
import LineGraph from "./LineGraph";
import { useSelector } from "react-redux";
import LineGraphLoader from "./LineGraphLoader";
import CircleGraphLoader from "./CircleGraphLoader";

const Graphs = () => {
  const isLoading = useSelector((store) => store.influencers.isLoading);

  return (
    <div className="graphs_main mt-3 d-flex justify-content-between">
      {isLoading ? (
        <div className="curve_graph d-flex align-items-end justify-content-evenly">
          <LineGraphLoader width={30} height={200} />
          <LineGraphLoader width={30} height={100} />
          <LineGraphLoader width={30} height={300} />
          <LineGraphLoader width={30} height={350} />
          <LineGraphLoader width={30} height={200} />
          <LineGraphLoader width={30} height={100} />
          <LineGraphLoader width={30} height={200} />
        </div>
      ) : (
        <div className="curve_graph">
          <div className="d-flex align-items-center justify-content-between">
            <h6>Influencers by category</h6>
          </div>
          <LineGraph />
        </div>
      )}
      {isLoading ? (
        <div className="circle_graph">
          <CircleGraphLoader />
        </div>
      ) : (
        <div className="circle_graph">
          <h6>Influencers by type</h6>
          <CircleGraph />
        </div>
      )}
    </div>
  );
};

export default Graphs;
