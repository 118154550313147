import React from "react";
import InfluencerLoader from "./InfluencerLoader";
import { Link } from "react-router-dom";
import NoInfluencerFound from "./NoInfluencerFound";
import maleProfile from "../../assets/user-male.png";
import femaleProfile from "../../assets/user-female.png";
import { imgBaseUrl } from "../../apiConfig";

const InfluencersBody = ({ isLoading, allInfluencers }) => {
  return (
    <div className="row">
      {isLoading && <InfluencerLoader />}
      {allInfluencers?.length !== 0
        ? allInfluencers?.map((data, i) => (
            <div className="col-md-6" key={i}>
              <div className="infl_card_box mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="profile_img">
                    {data?.profilePic?.includes("https://instagram.") ||
                    data?.profilePic?.includes("cdninstagram.com") ? (
                      <img
                        src={`${imgBaseUrl}/proxy-image?url=${encodeURIComponent(
                          data?.profilePic
                        )}`}
                        alt={`${data?.Name} profile`}
                        onError={(e) =>
                          (e.target.src =
                            data?.Gender === "Male"
                              ? maleProfile
                              : femaleProfile)
                        }
                      />
                    ) : (
                      <img
                        src={
                          data?.profilePic && data?.profilePic !== "NA"
                            ? `${imgBaseUrl}/${data.profilePic}`
                            : data?.Gender === "Male"
                            ? maleProfile
                            : femaleProfile
                        }
                        alt={
                          data?.Name ? `${data.Name} profile` : "Profile image"
                        }
                        onError={(e) => {
                          e.target.src =
                            data?.Gender === "Male"
                              ? maleProfile
                              : femaleProfile;
                        }}
                      />
                    )}
                  </div>
                  <div className="profile_name">
                    <p className="name text-capitalize">{data?.Name}</p>
                    <p className="location">
                      {data?.Followers?.toLocaleString()} Followers
                    </p>
                    <p className="location text-capitalize">{data?.Location}</p>
                  </div>
                </div>
                <p className="tags">Category</p>
                <div className="badges">
                  {data?.primaryCategory?.map((cat, i) => (
                    <span
                      className="badge bg-light text-capitalize"
                      key={`${data?._id}-primary-${i}`}
                    >
                      {cat}
                    </span>
                  ))}
                  {/* {data?.secondaryCategory && (
                    <span className="badge bg-light">
                      {data?.secondaryCategory}
                    </span>
                  )} */}
                </div>
                <div className="btns">
                  <Link
                    to={`/influencer-profile/${data?._id}`}
                    className="btn btn_view"
                  >
                    View Profile
                  </Link>
                  <Link className="btn btn_edit" to={`/edit/${data?._id}`}>
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          ))
        : !isLoading && <NoInfluencerFound />}
    </div>
  );
};

export default InfluencersBody;
