import React from "react";
import { Link, NavLink } from "react-router-dom";
import { CgDatabase } from "react-icons/cg";
import {
  MdCampaign,
  MdDashboard,
  MdFormatListBulletedAdd,
} from "react-icons/md";
import logo from "../assets/logo.png";
import { FaUsers } from "react-icons/fa6";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const isAdmin = useSelector((store) => store.user.userData.isAdmin);
  const user = useSelector((store) => store.user);
  const {
    userData: { permissions: { showFullDashboard } = {} },
  } = user;
  return (
    <div className="sidebar">
      <div className="flex-shrink-0 dashboard_sidebar sidebar">
        <div className="logo">
          <Link className="simple-text">
            <div className="logo-img">
              <img src={logo} alt="logo" className="img" />
            </div>
          </Link>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {showFullDashboard && (
              <li className="nav-item">
                <NavLink to={"/"} className="nav-link">
                  <MdDashboard className="icons" /> Dashboard
                </NavLink>
              </li>
            )}
            <li className="nav-item">
              <NavLink to={"/influencers"} className="nav-link">
                <CgDatabase className="icons" /> Influencers
              </NavLink>
            </li>
            {(isAdmin ||
              user?.userData?.permissions?.uploadFile ||
              user?.userData?.permissions?.addData) && (
              <li className="nav-item">
                <NavLink to={"/add-data"} className="nav-link">
                  <MdFormatListBulletedAdd className="icons" /> Add Influencer
                </NavLink>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <NavLink to={"/users"} className="nav-link">
                  <FaUsers className="icons" /> Users
                </NavLink>
              </li>
            )}
            {(user?.userData?.permissions?.createCampaign ||
              user?.userData?.permissions?.deleteCampaign ||
              user?.userData?.permissions?.updateCampaign ||
              user?.userData?.permissions?.viewCampaign ||
              isAdmin) && (
              <li className="nav-item">
                <NavLink to={"/campaign/create-campaign"} className="nav-link">
                  <MdCampaign className="icons" /> Campaigns
                </NavLink>
              </li>
            )}
          </ul>
          {/* <div className="logout_box">
            <ProfilePic onLogout={onLogout} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
