import React from "react";
import pageNotFound from "../assets/page-not-found.png";

const PageNotFound = () => {
  return (
    <div className="container text-center page_not_found">
      <img src={pageNotFound} alt="page not found" />
      <h1>Page Not Found</h1>
      <p>Looks like this page does not exist</p>
    </div>
  );
};

export default PageNotFound;
