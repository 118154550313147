import React from "react";
import { FaPersonChalkboard, FaUserTie } from "react-icons/fa6";
import { MdCampaign } from "react-icons/md";

const CampaignBasicInfo = ({ campaignData }) => {
  return (
    <div className="personal_info w-100 bg-white">
      <div>
        <div className="d-flex align-items-center">
          <div className="camp_user">
            <FaUserTie />
          </div>
          <div>
            <p className="light_text">Sales Person Name</p>
            <p className="name text-capitalize">
              {campaignData?.salesPersonName}
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="camp_user">
            <FaPersonChalkboard />
          </div>
          <div>
            <p className="light_text">Reporting Manager Name</p>
            <p className="name text-capitalize">
              {campaignData?.reportingManagerName}
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="camp_user">
            <MdCampaign />
          </div>
          <div>
            <p className="light_text">Campaign Type</p>
            <p className="name text-capitalize">{campaignData?.campaignType}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignBasicInfo;
