import React from "react";
import clientNameImg from "../../assets/client-name.svg";
import clientAddressImg from "../../assets/client-address.svg";
import clientCodeImg from "../../assets/client-code.svg";
import gstDetailsImg from "../../assets/gst-details.svg";

const ClientDetails = ({
  clientName,
  gstDetails,
  clientAddress,
  clientCode,
}) => {
  return (
    <div>
      <div className="d-flex align-items-center client_details">
        <img src={clientNameImg} alt="client name" />
        <div>
          <p className="light_text">Client Name</p>
          <p className="name text-capitalize client_name_camp">
            {clientName && clientName[0]?.name}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center mt-4 client_details">
        <img src={gstDetailsImg} alt="gst details" />
        <div>
          <p className="light_text">GST Details</p>
          <p className="name text-capitalize client_name_camp">{gstDetails}</p>
        </div>
      </div>
      <div className="d-flex align-items-center mt-4 client_details">
        <img src={clientAddressImg} alt="client address" />
        <div>
          <p className="light_text">Client Address</p>
          <p className="name text-capitalize client_name_camp">
            {clientAddress}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center mt-4 client_details">
        <img src={clientCodeImg} alt="client code" />
        <div>
          <p className="light_text">Client Code</p>
          <p className="name text-capitalize client_name_camp">{clientCode}</p>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
