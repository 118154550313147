import React from "react";
import "./TableLoader.scss";

const TableLoader = ({ rows, columns }) => {
  return (
    <table className="tg">
      <tbody>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <td className="tg-cly1" key={colIndex}>
                <div className="line"></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableLoader;
