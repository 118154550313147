import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

function LineGraphLoader({ width, height }) {
  return (
    <Stack spacing={1}>
      {/* <Skeleton variant="text" sx={{ fontSize: "1rem", width: "200px" }} /> */}
      <Skeleton
        variant="rounded"
        width={width}
        height={height}
        sx={{ margin: "10px" }}
      />
    </Stack>
  );
}

export default LineGraphLoader;
