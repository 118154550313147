import React from "react";
import ScrollToTop from "../ScrollToTop";
import Sidebar from "../Sidebar";
import CampaignSidebar from "./CampaignSidebar";
import CreateCampaign from "./CreateCampaign";
import "./Campaign.scss";
import ProfilePic from "../ProfilePic";

const Campaigns = ({ onLogout }) => {
  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Campaigns</h4>
                <div className="logout_box ms-3">
                  <ProfilePic onLogout={onLogout} />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <CampaignSidebar />
              </div>
              <div className="col-md-9 mt-3">
                <CreateCampaign />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
