import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const useGetAllInfluencers = (
  name,
  userName,
  quality,
  primaryCat,
  gender,
  influencerType,
  locations,
  followers,
  avgViews,
  fromDate,
  toDate,
  isGridView,
  typeOfUpload,
  paginationModel,
  setPaginationModel
) => {
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const getData = async (reset = false) => {
    if (reset || page > 1 || paginationModel?.page >= 0) {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("token");

        const response = await fetch(
          `${baseUrl}/all-influencers?page=${
            isGridView ? page : paginationModel?.page + 1
          }&limit=${
            isGridView ? 100 : paginationModel?.pageSize
          }&name=${name}&Username=${userName}&Quallity=${quality}&primaryCategory=${primaryCat}&Gender=${gender}&Type=${influencerType}&Location=${locations}&Followers=${followers}&avgViews=${avgViews}&fromDate=${fromDate}&toDate=${toDate}&uploadType=${typeOfUpload}`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );

        const data = await response.json();
        if (data && data.influencers) {
          setAllInfluencers((prev) =>
            reset || !isGridView
              ? data.influencers
              : [...prev, ...data.influencers]
          );
          setTotalPages(data.totalPages);
          setTotalCount(data.total);
        } else {
          setAllInfluencers([]);
        }
      } catch (error) {
        console.error(error);
        setAllInfluencers([]);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const debouncedGetData = debounce(() => {
    getData(true);
  }, 300);

  useEffect(() => {
    setPage(1);
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: 0,
    }));
    debouncedGetData();
  }, [
    name,
    userName,
    quality,
    primaryCat,
    gender,
    influencerType,
    locations,
    followers,
    avgViews,
    fromDate,
    toDate,
    isGridView,
    typeOfUpload,
  ]);

  useEffect(() => {
    if (page > 1 || paginationModel?.page >= 0) {
      getData(!isGridView);
    }
  }, [page, paginationModel]);

  const handleInfiniteScroll = async () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight &&
      page < totalPages &&
      !isLoading &&
      isGridView
    ) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (isGridView) {
      window.addEventListener("scroll", handleInfiniteScroll);
    }
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, [page, totalPages, isLoading, isGridView]);

  const refreshData = async () => {
    setPage(1);
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: 0,
    }));
    await getData(true);
  };

  return [isLoading, allInfluencers, refreshData, totalCount];
};

export default useGetAllInfluencers;
