import { Checkbox, TablePagination, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import instaLogo from "../../assets/insta-logo.svg";
import CampGraphsTab from "./CampGraphsTab";
import AddInfluencersButton from "./AddInfluencersButton";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import SnackbarWithMessage from "./SnackbarWithMessage";
import TableLoader from "../influencers/TableLoader";

const CampaignInfluencers = ({
  influencers,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalInfluencers,
  refreshData,
  isLoading,
}) => {
  const params = useParams();
  const { id } = params;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const removeInfluencersFromCampaign = async (campaignId, influencerIds) => {
    try {
      await axios.put(`${baseUrl}/remove-influencers/${campaignId}`, {
        influencerIds,
      });
      refreshData();
      setMessage("Influencers removed successfully");
      setBackgroundColor("#4caf50");
      setOpen(true);
    } catch (error) {
      console.error("Error removing influencers:", error.response.data.message);
      setMessage("Something went wrong. Please try again later.");
      setBackgroundColor("#f44336");
      setOpen(true);
    }
  };

  const handleSelectInfluencer = (influencerId) => {
    setSelectedInfluencers((prev) =>
      prev.includes(influencerId)
        ? prev.filter((id) => id !== influencerId)
        : [...prev, influencerId]
    );
  };

  const sendInvitations = async () => {
    const selectedInfluencersData = influencers.filter((influencer) =>
      selectedInfluencers.includes(influencer._id)
    );

    const campaignLink = "Your Campaign Link Here";
    try {
      await axios.post(`${baseUrl}/send-whatsapp`, {
        influencers: selectedInfluencersData,
        campaignLink,
      });
      setMessage("Invitations sent successfully");
      setBackgroundColor("#4caf50");
      setOpen(true);
      setSelectedInfluencers([]);
    } catch (error) {
      console.error("Error sending invitations:", error.response.data.message);
      setMessage("Something went wrong. Please try again later.");
      setBackgroundColor("#f44336");
      setOpen(true);
    }
  };

  return (
    <div className="row mt-4">
      <SnackbarWithMessage
        open={open}
        setOpen={setOpen}
        message={message}
        backgroundColor={backgroundColor}
      />
      <div className="col-md-7">
        <div className="camp_influencers_main">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
            <h4>Influencers</h4>
            <AddInfluencersButton
              campaignId={id}
              influencers={influencers}
              refreshCampData={refreshData}
              setOpen={setOpen}
              setMessage={setMessage}
            />
          </div>
          {isLoading ? (
            <TableLoader rows={6} columns={4} />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Followers</th>
                  <th>Quality</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {influencers?.map((influencer) => (
                  <tr key={influencer?._id}>
                    <td>
                      <Checkbox
                        checked={selectedInfluencers.includes(influencer?._id)}
                        onChange={() => handleSelectInfluencer(influencer?._id)}
                      />
                    </td>
                    <td>{influencer?.Name}</td>
                    <td>{influencer?.Followers}</td>
                    <td>{influencer?.Quallity}</td>
                    <td>
                      <Tooltip title="View Profile" placement="top">
                        <a
                          href={influencer?.instagramProfile}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={instaLogo}
                            alt="insta logo"
                            className="insta_logo"
                          />
                        </a>
                      </Tooltip>
                      <Tooltip title="Remove" placement="top">
                        <button
                          onClick={() =>
                            removeInfluencersFromCampaign(id, influencer?._id)
                          }
                          className="btn btn_edit p-0 ms-3 text-danger"
                        >
                          <RxCrossCircled style={{ marginBottom: "3px" }} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="mt-3 d-flex align-items-center justify-content-between">
            <button
              onClick={sendInvitations}
              disabled={selectedInfluencers.length === 0}
              className={
                selectedInfluencers?.length === 0
                  ? "add_btn disabled_invite"
                  : "add_btn"
              }
            >
              Invite
            </button>
            <TablePagination
              component="div"
              count={totalInfluencers || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="camp_influencers_main">
          <CampGraphsTab />
        </div>
      </div>
    </div>
  );
};

export default CampaignInfluencers;
