import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

function OverviewLoader() {
  return (
    <Skeleton
      sx={{ bgcolor: "grey.200", borderRadius: "10px" }}
      variant="rectangular"
      width={210}
      height={75}
    />
  );
}

export default OverviewLoader;
