import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { imgBaseUrl } from "../../apiConfig";
import { Link, useParams } from "react-router-dom";

const CampaignHeader = ({ campaignData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dateStr = campaignData?.createdAt;
  const date = new Date(dateStr);
  const params = useParams();
  const { id } = params;

  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  const boxRef = useRef(null);

  const openEditorBtn = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="campaign_header position-relative">
      <div className="d-flex align-items-center">
        <div className="campaign_logo">
          {campaignData?.campaignLogo == null ? (
            <p>{campaignData?.campaignName?.slice(0, 1)?.toUpperCase()}</p>
          ) : (
            <img
              src={`${imgBaseUrl}/${campaignData?.campaignLogo}`}
              alt={`${campaignData?.campaignName} logo`}
            />
          )}
        </div>
        <div className="ms-2">
          <h2 className="campaign_name">{campaignData?.campaignName}</h2>
          <p className="light_text">
            Created on <span>{formattedDate}</span>
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="status">
          <span>Status </span>
          {campaignData?.campaignStatus?.toLowerCase() === "active" && (
            <span className="chip">{campaignData?.campaignStatus}</span>
          )}
          {campaignData?.campaignStatus?.toLowerCase() ===
            "not started yet" && (
            <span className="notStarted_chip chip">
              {campaignData?.campaignStatus}
            </span>
          )}
          {campaignData?.campaignStatus?.toLowerCase() === "on hold" && (
            <span className="onHold_chip chip">
              {campaignData?.campaignStatus}
            </span>
          )}
          {campaignData?.campaignStatus?.toLowerCase() === "completed" && (
            <span className="completed_chip chip">
              {campaignData?.campaignStatus}
            </span>
          )}
        </div>
        <BsThreeDotsVertical
          className="ms-4 three_dot"
          onClick={openEditorBtn}
        />
        {isOpen && (
          <div className="open_box" ref={boxRef}>
            <ul>
              <li>
                <Link to={`/campaign/edit-campaign/${id}`}>Edit</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignHeader;
