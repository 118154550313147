import { TablePagination, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";

const InfluencerCampaigns = ({ campaigns }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="infl_campaigns_table m-auto ">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Client Name</th>
            <th>Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {campaigns?.map((campaign) => (
            <tr key={campaign?._id}>
              <td>{campaign?.campaignName}</td>
              <td>{campaign?.clientName[0]?.name}</td>
              <td>{campaign?.campaignStatus}</td>
              <td>
                <Tooltip title="View Campaign" placement="top">
                  <Link to={`/campaign/campaign-details/${campaign?._id}`}>
                    <IoEyeOutline />
                  </Link>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-3">
        <TablePagination
          component="div"
          count={campaigns?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default InfluencerCampaigns;
