import React from "react";
import Sidebar from "../Sidebar";
import "./Dashboard.scss";
import Overview from "./Overview";
import Graphs from "./Graphs";
import TopInfluencers from "./TopInfluencers";
import ScrollToTop from "../ScrollToTop";
import ProfilePic from "../ProfilePic";

const Dashboard = ({ onLogout }) => {
  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="dashboard_main">
            <div className="dashboard_header">
              <h4>Dashboard</h4>
              <div className="logout_box">
                <ProfilePic onLogout={onLogout} />
              </div>
            </div>
            <Overview />
            <Graphs />
            <TopInfluencers />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
